import Home from "./components/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
    return (
        <div>
            <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
            integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
            crossOrigin="anonymous"
            />
            <Router>
                <Routes>
                    <Route path="/adapters-catalog" element={<Home />} />
                    <Route path="/gsp-account-adapter-frm" element={<div />} />
                    <Route path="/mmd-adapter-frm" element={<div/>}/>
                    <Route path="/deal-enrichment-adapter-frm" element={<div />} />
					<Route path="/avm-reservation-adapter-frm" element={<div/>}/>
                </Routes>
            </Router>
        </div>
    )
}

export default App;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-container {
    padding-top: 1%;
    margin-left: 15%;
    margin-right: 15%;
}

.catalog-card {
    background: #E3EBED !important;
    border-color: #C4C5C6 !important;
    border-width: 1px !important;
}

.catalog-card-body {
    font-size: .90em;
}

.catalog-card-footer {
    font-size: .95em;
}`, "",{"version":3,"sources":["webpack://./src/styles/adapter-catalog.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;IAChC,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".link-container {\n    padding-top: 1%;\n    margin-left: 15%;\n    margin-right: 15%;\n}\n\n.catalog-card {\n    background: #E3EBED !important;\n    border-color: #C4C5C6 !important;\n    border-width: 1px !important;\n}\n\n.catalog-card-body {\n    font-size: .90em;\n}\n\n.catalog-card-footer {\n    font-size: .95em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

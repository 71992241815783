import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import adapters from '../resources/navbar-labels-routes.json'

import "../styles/adapter-catalog.css"

// TODO: add scroll grid styling for
// TODO: add search bar + filters
function AdapterCatalog() {
  return (
    <div className="link-container">
      <div>
        <Row xs={1} md={3} className="g-4">
          {adapters.items.map((item, index) => (
              <Col key={index}>
                <Card className="catalog-card">
                  <Card.Header as="h6">{item.label} <div style={{float: "right"}}><Badge pill bg="primary">New</Badge></div></Card.Header>
                  <Card.Body className="catalog-card-body">
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>
                  <Card.Footer className="catalog-card-footer">
                    <Card.Link href="#">Contract</Card.Link>
                    <Card.Link as={Link} to={item.questionnaire}>Questionnaire</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
}

export default AdapterCatalog;